import type { ApexOptions } from "apexcharts";

export type BarData = {
  xaxis: {
    categories: string[];
    tickAmount?: number | "dataPoints";
  };
  series: {
    name: string;
    data: number[];
    disabled?: boolean;
  }[];
};

export type BoxPlotData = {
  series: { type: "boxPlot"; data: { x: string; y: number[] }[] }[];
};

export type HeatMapData = {
  series: { name: string; data: number[] }[];
};

/**TODO: Rewrite to runtime safe datapoints like  */
export type PieData = {
  labels: string[];
  series: number[];
};

export type ScatterData = {
  series: {
    name: string;
    data: [number, number][];
  }[];
};

export type TreemapData = {
  series: { data: { x: string; y: number }[] }[];
};

export type OptionsConfig = ApexOptions["chart"] & {
  horizontal?: boolean;
  boxPlotColorUpper?: string;
  boxPlotColorLower?: string;
  sparkline?: boolean;
  labelFormatter?: ApexDataLabels["formatter"];
  tooltipFormatter?: ApexTooltipY["formatter"];
  xAxisFormatter?: NonNullable<ApexXAxis["labels"]>["formatter"];
  yAxisFormatter?: NonNullable<ApexYAxis["labels"]>["formatter"];
  rotate?: NonNullable<ApexXAxis["labels"]>["rotate"];
  rotateAlways?: NonNullable<ApexXAxis["labels"]>["rotateAlways"];
  colors?: any[];
  omitTooltip?: boolean;
  tooltipTitle?: boolean;
  tooltipFixedToTop?: boolean;
  labels?: ApexOptions["labels"];
  xaxis?: ApexXAxis;
  yaxis?: ApexYAxis;
  downloadFilename?: string;
};

const getOptions = (options: OptionsConfig): ApexOptions => {
  const o: ApexOptions = {
    plotOptions: {
      pie: {
        donut: {
          size: "50%",
        },
      },

      bar: {
        horizontal: options.horizontal || false,
      },

      treemap: {
        distributed: true,
        enableShades: false,
      },

      heatmap: {
        distributed: true,
        enableShades: false,
        radius: 0,
      },

      boxPlot: {
        colors: {
          upper: options.boxPlotColorUpper || "#000000",
          lower: options.boxPlotColorLower || "#00000025",
        },
      },
    },

    chart: {
      toolbar: {
        show: false,
        tools: {
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false,
        },
        export: {
          svg: {
            filename: options.downloadFilename || "chart",
          },
        },
      },
      id: "chart",
      type: options.type,
      stacked: options.stacked || false,
      parentHeightOffset: 0,

      sparkline: {
        enabled: options.sparkline || false,
      },

      animations: {
        enabled: false,
      },

      // dynamicAnimation: {
      //   enabled: false,
      // },

      selection: {
        enabled: false,
      },
    },

    forecastDataPoints: {
      count: 0,
      dashArray: 2,
    },

    grid: {
      show: options.sparkline === true ? false : true,
      borderColor: "#00000015",
      strokeDashArray: 2,
    },

    legend: {
      show: false,
    },

    dataLabels: {
      enabled: options.type && ["treemap", "heatmap"].includes(options.type),
      textAnchor: "middle",
      formatter: options.labelFormatter,
    },

    colors: options.colors || [],

    states: {
      normal: {
        filter: {
          type: "none",
          value: 0,
        },
      },

      hover: {
        filter: {
          type: "none",
          value: 0,
        },
      },

      active: {
        filter: {
          type: "none",
          value: 0,
        },
      },
    },

    tooltip: {
      enabled: options.omitTooltip || (options.type && ["pie", "donut"].includes(options.type)) ? false : true,

      marker: {
        show: true,
      },

      y: {
        formatter: options.tooltipFormatter,

        title: {
          formatter: (s: string) => {
            if (options.tooltipTitle) {
              return s;
            } else {
              return "";
            }
          },
        },
      },

      fixed: {
        enabled: options.tooltipFixedToTop,
        position: "topLeft",
        offsetX: 10,
        offsetY: 4,
      },
    },

    stroke: {
      curve: "smooth",

      width: options.type && ["pie", "donut", "heatmap", "treemap", "boxPlot"].includes(options.type) ? 1 : 2,

      ...(() => {
        if (options.type === "boxPlot") {
          return { colors: ["#00000050"] };
        }
      })(),
    },

    markers: {
      size: options.type === "scatter" ? 6 : 0,
      strokeWidth: 0,
      radius: 0,

      hover: {
        size: options.type === "scatter" ? 8 : 0,
        sizeOffset: 0,
      },
    },

    labels: options.labels || [],

    xaxis: {
      axisBorder: {
        show: false,
      },

      axisTicks: {
        show: false,
      },

      crosshairs: {
        show: options.type && ["line", "bar", "area", "scatter", "treemap"].includes(options.type),

        opacity: 1,
        width: options.type === "bar" ? "barWidth" : "2",

        stroke: {
          color: "#00000000",
        },

        fill: {
          type: "solid",
          color: "#8D8D8D",
        },
      },

      tooltip: {
        enabled: false,
      },

      labels: {
        formatter: options.xAxisFormatter,
        rotate: options.rotate,
        rotateAlways: options.rotateAlways,
        hideOverlappingLabels: true,
      },

      ...(options.xaxis || {}),
    },

    yaxis: {
      axisBorder: {
        show: false,
      },

      axisTicks: {
        show: false,
      },

      crosshairs: {
        show: false,
      },

      tooltip: {
        enabled: false,
      },

      labels: {
        formatter: options.yAxisFormatter,
      },

      ...(options.yaxis || {}),
    },
  };

  if (options.type == "area") {
    o.fill = {
      type: "gradient",
      gradient: {
        opacityFrom: 0.7,
        opacityTo: 0.5,
        stops: [0, 95, 100],
      },
    };
  } else {
    o.fill = {
      type: "solid",
      colors: options.colors || [],
      opacity: options.type && ["donut", "pie", "line", "bar", "scatter", "treemap", "boxPlot"].includes(options.type) ? 1 : 0.25,
    };
  }

  return o;
};

export default getOptions;
